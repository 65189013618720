import axios from "axios";
import React, { FC, useCallback, useEffect } from "react";
import { useState } from "react";
import { useNUI } from "../../Hooks/useNUI";
import styles from './inventory.module.scss';

type InventoryData = {
    inventory: (Item | null)[],
    size: number
    weight: number,
    target: {
        inventory: (Item | null)[],
        size: number,
        weight: number,
        name: string
    } | null
}

type Item = {
    title: string,
    left: string,
    right: string,
    icon_path: string,
}

const g = global as any;

const Inventory: FC = () => {
    const data: InventoryData = useNUI('cs-inventory:inventory', {
        inventory: new Array(24).fill(null),
        size: 24,
        weight: 20,
        target: null
    });
    const open: boolean = useNUI('cs-inventory:show', false);

    const [selectedItem, setSelectedItem] = useState(null as Item | null)

    const exit = useCallback(() => {
        axios.post(`https://${g.GetParentResourceName()}/cs-inventory:hide`, {});
    }, [])

    useEffect(() => {
        console.log(data);
    }, [data])

    return (
        <>
        { open ?
            <div className={styles.inventoryWrapper} onKeyDown={ exit }>
                <div className={styles.inventory}>
                    <div className={styles.own}>
                        <label className={styles.title}>
                            You
                        </label>
                        <div className={styles.items}>
                            {data.inventory.map((item, index) => {
                                return (
                                    <div className={`${styles.item} ${item === null ? styles.empty : null}`} key={`own-item-${index}`} onClick={ () => setSelectedItem(item) }>
                                        <div className={styles.itemContent} style={{ backgroundImage: `url(${item?.icon_path}` }}>
                                            <div className={styles.data}>
                                                <div className={styles.left}>
                                                    { item?.left }
                                                </div>
                                                <div className={styles.right}>
                                                    { item?.right }
                                                </div>
                                            </div>
                                            <div className={styles.name}>
                                                { item?.title }
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                    <div className={styles.interactWrapper}>
                        <div className={styles.interact}>
                            <div className={styles.button}>
                                Use
                            </div>
                            <div className={styles.items}>
                                <div className={`${styles.item} ${selectedItem === null ? styles.empty : null}`}>
                                    <div className={styles.itemContent} style={{ backgroundImage: `url(${selectedItem?.icon_path}` }}>
                                        <div className={styles.data}>
                                            <div className={styles.left}>
                                                { selectedItem?.left }
                                            </div>
                                            <div className={styles.right}>
                                                { selectedItem?.right}
                                            </div>
                                        </div>
                                        <div className={styles.name}>
                                            { selectedItem?.title }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={styles.button}>
                                Drop
                            </div>
                        </div>

                        <div className={styles.close} onClick={ exit }>
                            Close
                        </div>
                    </div>
                    {data.target !== null ?
                        <div className={styles.other}>
                            <label className={styles.title}>
                                {data.target.name}
                            </label>
                            <div className={styles.items}>
                                {data.target.inventory.map((item, index) => {
                                    return (
                                        <div className={`${styles.item} ${item === null ? styles.empty : null}`} key={`own-item-${index}`}>
                                            <div className={styles.itemContent} style={{ backgroundImage: `url(${item?.icon_path}` }}>
                                                <div className={styles.data}>
                                                    <div className={styles.left}>
                                                        { item?.left }
                                                    </div>
                                                    <div className={styles.right}>
                                                        { item?.right }
                                                    </div>
                                                </div>
                                                <div className={styles.name}>
                                                    { item?.title }
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    : null}
                </div>
            </div>
        : null }
        </>
    )
}

export default Inventory;
