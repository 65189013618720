import React from "react";
import { useEffect } from "react";
import { FC } from "react";
import { useNUI } from "../../Hooks/useNUI";
import styles from "./interaction.module.scss";

type InteractionItem = {
    interaction: {
        icon: string,
        title: string
    }
}

const InteractionTarget: FC = () => {
    const data = useNUI('interactionTarget', null as null | InteractionItem);

    useEffect(() => {
        console.log(data);
    }, [data]);

    return (
        <div className={ styles.target }>
            { data ? 
                <>
                    <i className={data.interaction.icon}/>
                    <p>{ data.interaction.title }</p>
                </>
            : null }
        </div>
    )
}

export default InteractionTarget;
