
import axios from "axios";
import React, { FC, useCallback, useEffect } from "react";
import { useState } from "react";
import { useNUI } from "../../Hooks/useNUI";
import styles from "./characters.module.scss";
import { Character, CharacterInfo, ClothingKeys, ClothingMode, UI } from "./types";

const g = global as any;

const CharacterUI: FC = () => {
    const isCreate = useNUI('cs-character:setCreate', false);
    const uiMode = useNUI('cs-character:ui', null as UI);
    const clothingMode = useNUI('cs-character:clothing-mode', 'all' as ClothingMode);
    const characterInfo = useNUI('cs-character:character-info', null as CharacterInfo | null);

    useEffect(() => {
        console.log(uiMode);
    }, [uiMode]);

    useEffect(() => {
        console.log(clothingMode);
    }, [clothingMode]);

    useEffect(() => {
        console.log(characterInfo);
    }, [characterInfo]);

    return (
        <div>
            {uiMode === "details" ?
                <DetailsComponent uiMode={uiMode} clothingMode={clothingMode} characterInfo={characterInfo} isCreate={isCreate} />
                : null}
            {uiMode === "ped" ?
                <PedComponent uiMode={uiMode} clothingMode={clothingMode} characterInfo={characterInfo} isCreate={isCreate} />
                : null}
            {uiMode === "outfits" ?
                <OutfitComponent uiMode={uiMode} clothingMode={clothingMode} characterInfo={characterInfo} isCreate={isCreate} />
                : null}
            {uiMode === "select" ?
                <SelectComponent uiMode={uiMode} clothingMode={clothingMode} characterInfo={characterInfo} isCreate={isCreate} />
                : null}
        </div>
    )
}

type CharacterProps = {
    uiMode: UI,
    clothingMode: ClothingMode,
    characterInfo: CharacterInfo | null,
    isCreate: boolean
}

const DetailsComponent = ({ uiMode, clothingMode, characterInfo }: CharacterProps) => {
    const setGender = useCallback((gender: string) => {
        axios.post(`https://${g.GetParentResourceName()}/cs-character:gender`, {
            gender: gender
        });
    }, []);

    const updateCharacter = useCallback((character: Character) => {
        axios.post(`https://${g.GetParentResourceName()}/cs-character:character`, {
            character: character
        });
    }, []);

    const next = useCallback(() => {
        axios.post(`https://${g.GetParentResourceName()}/cs-character:ped`, {});
    }, []);

    const onChangeName = useCallback((event) => {
        const newData = Object.assign({}, characterInfo) as any;
        if (newData.character) {
            newData.character[event.target.name] = event.target.value;
        }

        updateCharacter(newData.character);
    }, [characterInfo, updateCharacter]);

    const onChangeDOB = useCallback((index, value) => {
        const newData = Object.assign({}, characterInfo) as any;
        if (newData.character) {
            newData.character.dob[index] = value;
        }

        updateCharacter(newData.character);
    }, [characterInfo, updateCharacter]);

    const onNext = useCallback(() => {
        if (characterInfo?.character?.first_name !== "") {
            if (characterInfo?.character?.last_name !== "") {
                next();
            }
        }
    }, [characterInfo, next]);

    return (
        <div className={styles.detailsWrapper}>
            <div className={styles.details}>
                <div className={styles.field}>
                    <label>First Name:</label>
                    <input name="first_name" type="text" value={characterInfo?.character?.first_name} placeholder="First Name" onChange={onChangeName} />
                </div>
                <div className={styles.field}>
                    <label>Last Name:</label>
                    <input name="last_name" type="text" value={characterInfo?.character?.last_name} placeholder="Last Name" onChange={onChangeName} />
                </div>
                <div className={styles.field}>
                    <label>Gender:</label>
                    <div className={styles.genderRow}>
                        <div className={styles.button} onClick={() => { setGender('male') }}>
                            Male
                        </div>
                        <div className={styles.button} onClick={() => { setGender('female') }}>
                            Female
                        </div>
                    </div>
                </div>
                <div className={styles.field}>
                    <label>Date of Birth:</label>
                    <div className={styles.dobRow}>
                        <input type="number" name="day" value={characterInfo?.character?.dob[0]} onChange={(e) => onChangeDOB(0, e.target.valueAsNumber)} />
                        <input type="number" name="month" value={characterInfo?.character?.dob[1]} onChange={(e) => onChangeDOB(1, e.target.valueAsNumber)} />
                        <input type="number" name="year" value={characterInfo?.character?.dob[2]} onChange={(e) => onChangeDOB(2, e.target.valueAsNumber)} />
                    </div>
                </div>
                <div className={styles.next}>
                    {characterInfo?.characters.length !== 0 ?
                        <div className={styles.button}>
                            Cancel
                        </div>
                        : null}
                    <div className={styles.button} onClick={onNext}>
                        Next
                    </div>
                </div>
            </div>
        </div>
    )
}

const PedComponent = ({ uiMode, clothingMode, characterInfo }: CharacterProps) => {
    const updateCharacter = useCallback((character: Character) => {
        axios.post(`https://${g.GetParentResourceName()}/cs-character:character`, {
            character: character
        });
    }, []);

    const back = useCallback(() => {
        axios.post(`https://${g.GetParentResourceName()}/cs-character:details`, {});
    }, []);

    const next = useCallback(() => {
        axios.post(`https://${g.GetParentResourceName()}/cs-character:outfit`, {});
    }, []);

    const changeFace = useCallback((value: number) => {
        const newData = Object.assign({}, characterInfo);
        if (newData.character) {
            newData.character.ped.face.drawable = newData.character.ped.face.drawable + value;
            updateCharacter(newData.character);
        }
    }, [characterInfo, updateCharacter]);

    return (
        <div className={styles.pedWrapper}>
            <div className={styles.ped}>
                <div className={styles.component}>
                    <label>Face</label>
                    <div className={styles.componentRow}>
                        <div className={styles.button} onClick={() => changeFace(-1)}>
                            -
                        </div>
                        <div className={styles.componentValue}>
                            {characterInfo?.character?.ped.face.drawable}
                        </div>
                        <div className={styles.button} onClick={() => changeFace(1)}>
                            +
                        </div>
                    </div>
                </div>
                <div className={styles.next}>
                    <div className={styles.button} onClick={back}>
                        Back
                    </div>
                    <div className={styles.button} onClick={next}>
                        Next
                    </div>
                </div>
            </div>
        </div>
    )
}

export type slot = ClothingKeys | null;
const OutfitComponent = ({ uiMode, clothingMode, characterInfo, isCreate }: CharacterProps) => {
    const [slot, setSlot] = useState(null as slot);

    const back = useCallback(() => {
        axios.post(`https://${g.GetParentResourceName()}/cs-character:ped`, {});
    }, []);

    const next = useCallback(() => {
        axios.post(`https://${g.GetParentResourceName()}/cs-character:update`, characterInfo);
    }, [characterInfo]);

    return (
        <div className={styles.outfitWrapper}>
            <div className={styles.outfit}>
                {slot ?
                    <SlotComponent uiMode={uiMode} clothingMode={clothingMode} characterInfo={characterInfo} isCreate={isCreate} title={slot} setSlot={setSlot} />
                    :
                    <div className={styles.outfitMain}>
                        <div className={styles.button} onClick={() => setSlot('mask')}>
                            Masks
                        </div>
                        <div className={styles.button} onClick={() => setSlot('hair')}>
                            Hair
                        </div>
                        <div className={styles.button} onClick={() => setSlot('torso')}>
                            Torso
                        </div>
                        <div className={styles.button} onClick={() => setSlot('legs')}>
                            Pants
                        </div>
                        <div className={styles.button} onClick={() => setSlot('bag')}>
                            Bags
                        </div>
                        <div className={styles.button} onClick={() => setSlot('foot')}>
                            Shoes
                        </div>
                        <div className={styles.button} onClick={() => setSlot('accessory')}>
                            Accessories
                        </div>
                        <div className={styles.button} onClick={() => setSlot('undershirt')}>
                            Undershirts
                        </div>
                        <div className={styles.button} onClick={() => setSlot('armour')}>
                            Armour
                        </div>
                        <div className={styles.button} onClick={() => setSlot('decal')}>
                            Decals
                        </div>
                        <div className={styles.button} onClick={() => setSlot('top')}>
                            Tops
                        </div>
                        <div className={styles.next}>
                            <div className={styles.button} onClick={back}>
                                Back
                            </div>
                            <div className={styles.button} onClick={next}>
                                { isCreate ? "Create" : "Save" }
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}

type SlotProps = {
    uiMode: UI,
    clothingMode: ClothingMode,
    characterInfo: CharacterInfo | null,
    isCreate: boolean,
    title: slot,
    setSlot: (val: slot) => void
}

const toTitleCase = (str: string) => {
    return str.replace(
        /\w\S*/g,
        function (txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        }
    );
}

const SlotComponent = ({ uiMode, clothingMode, characterInfo, title, setSlot }: SlotProps) => {
    const updateCharacter = useCallback((character: Character) => {
        axios.post(`https://${g.GetParentResourceName()}/cs-character:character`, {
            character: character
        });
    }, []);

    const changeItem = (value: number) => {
        if (title === null) {
            return null
        }

        const newData = Object.assign({}, characterInfo);
        if (newData.character) {
            newData.character.clothing[title].drawable = newData.character.clothing[title].drawable + value;
            updateCharacter(newData.character);
        }
    }

    const changeTexture = (value: number) => {
        if (title === null) {
            return null
        }

        const newData = Object.assign({}, characterInfo);
        if (newData.character) {
            newData.character.clothing[title].texture = newData.character.clothing[title].texture + value;
            updateCharacter(newData.character);
        }
    }

    const changePalate = (value: number) => {
        if (title === null) {
            return null
        }

        const newData = Object.assign({}, characterInfo);
        if (newData.character) {
            newData.character.clothing[title].palate = newData.character.clothing[title].palate + value;
            updateCharacter(newData.character);
        }
    }

    if (title === null) {
        return (
            <div></div>
        );
    }

    return (
        <div className={styles.slot}>
            <label>{toTitleCase(title as string)}</label>
            <div className={styles.component}>
                <label>Item</label>
                <div className={styles.componentRow}>
                    <div className={styles.button} onClick={() => changeItem(-1)}>
                        -
                    </div>
                    <div className={styles.componentValue}>
                        {characterInfo?.character?.clothing[title].drawable}
                    </div>
                    <div className={styles.button} onClick={() => changeItem(1)}>
                        +
                    </div>
                </div>
            </div>
            <div className={styles.component}>
                <label>Texture</label>
                <div className={styles.componentRow}>
                    <div className={styles.button} onClick={() => changeTexture(-1)}>
                        -
                    </div>
                    <div className={styles.componentValue}>
                        {characterInfo?.character?.clothing[title].texture}
                    </div>
                    <div className={styles.button} onClick={() => changeTexture(1)}>
                        +
                    </div>
                </div>
            </div>
            <div className={styles.component}>
                <label>Colour</label>
                <div className={styles.componentRow}>
                    <div className={styles.button} onClick={() => changePalate(-1)}>
                        -
                    </div>
                    <div className={styles.componentValue}>
                        {characterInfo?.character?.clothing[title].palate}
                    </div>
                    <div className={styles.button} onClick={() => changePalate(1)}>
                        +
                    </div>
                </div>
            </div>
            <div className={styles.next}>
                <div className={styles.button} onClick={() => setSlot(null)}>
                    Back
                </div>
            </div>
        </div>
    );
}

const SelectComponent = ({ uiMode, clothingMode, characterInfo }: CharacterProps) => {
    console.log(characterInfo);

    const accept = useCallback(() => {
        axios.post(`https://${g.GetParentResourceName()}/cs-character:accept`, {});
    }, []);

    const create = useCallback(() => {
        axios.post(`https://${g.GetParentResourceName()}/cs-character:create`, {});
    }, []);

    const charSel = useCallback((value: number) => {
        axios.post(`https://${g.GetParentResourceName()}/cs-character:index`, {
            index: characterInfo ? characterInfo?.selectIndex + value : 0
        });
    }, [characterInfo]);
    
    if (characterInfo === null) {
        return (
            <div></div>
        )
    }

    return (
        <div className={styles.selectWrapper}>
            <div className={styles.select}>
                <label>
                    Characters
                    <div className={styles.key}>
                        { characterInfo?.selectIndex + 1 } / { characterInfo?.characters.length }
                    </div>
                </label>

                <div className={ styles.detail }>
                    <label>Name:</label>
                    <p>{ characterInfo?.character?.first_name } { characterInfo?.character?.last_name }</p>
                </div>
                
                <div className={ styles.detail }>
                    <label>Date of Birth:</label>
                    <p>{ characterInfo.character?.dob[0] }/{ characterInfo.character?.dob[1] }/{ characterInfo.character?.dob[2] }</p>
                </div>

                <div className={ styles.index }>
                    { characterInfo.selectIndex >= 1 ?
                        <div className={ styles.button } onClick={() => charSel(-1)}>&lt;</div>
                    : null }
                    { characterInfo.selectIndex + 1 < characterInfo.characters.length ?
                        <div className={ styles.button } onClick={() => charSel(1)}>&gt;</div>
                    : null }
                </div>
                <div className={styles.accept}>
                    <div className={styles.button} onClick={accept}>
                        Play
                    </div>

                    <div className={styles.button} onClick={create}>
                        New
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CharacterUI;
