import React from "react";
import { useEffect } from "react";
import { CSSProperties } from "react";
import { useState } from "react";
import { FC } from "react";
import { useMapNUI } from "../../Hooks/useNUI";
import styles from "./hud.module.scss";
import { buildStyles, CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

export type HudElement = {
    icon: string,
    type: 'value' | 'percentage',
    color: string,
    value: number | string
}

const HUD: FC = () => {
    const data = useMapNUI('HUD', new Map<string, HudElement>());
    const [localData, setLocalData] = useState([] as HudElement[]);

    useEffect(() => {
        const newLocalData: HudElement[] = [];
        data.forEach((value, string) => {
            console.log(value);
            newLocalData.push(value);
        });
        setLocalData(newLocalData);
    }, [data, setLocalData]);

    return (
        <div className={ styles.HUD }>
            { localData.map((item, index) => {
                return <HudItem key={`hud-item-${index}`} item={item}/>
            })}
        </div>
    )
}

type HudItemProps = {
    item: HudElement
}

interface HudItemStyle extends CSSProperties {
    '--item-color': string,
    '--item-value': number
}

function HudItem ({ item }: HudItemProps) {
    console.log(item);

    const classes = styles.HUDItem + ' ' + (item.type === 'percentage' ? styles.HUDItemPercentage : styles.HUDItemValue);

    return (
        <>
            { item.type === "percentage" ? 
                <div className={classes} style={ { '--item-color': item.color, '--item-value': item.value } as HudItemStyle }>
                    <div className={styles.circle}>
                        <CircularProgressbar value={typeof item.value === "number" ? item.value : parseInt(item.value)} styles={ buildStyles({
                            pathColor: item.color,
                            strokeLinecap: 'butt',
                        })}/>
                        <i className={ styles.icon + " " + item.icon }></i>
                    </div>
                </div>
            : null }
        </>
    );
}

export default HUD;
