import React from 'react';
import ReactDOM from 'react-dom';
import { 
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import CharacterUI from './Components/Characters';
import HUD from './Components/HUD';
import InteractionTarget from './Components/InteractionTarget';
import Inventory from './Components/Inventory';
import './index.scss';

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <Switch>
        <Route path="/hud" component={HUD}/>
        <Route path="/target" component={InteractionTarget}/>
        <Route path="/character" component={CharacterUI}/>
        <Route path="/inventory" component={Inventory}/>
      </Switch>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);
