import { useState, useEffect, useCallback } from "react";

function useNUI<Type>(key: string, initialState: Type): Type
{
    const [state, setState] = useState(initialState);

    type MessageData<Type> = {
        key: string,
        data: Type,
    }

    const handleMessage = useCallback((message) => {
        try {
            const messageData: MessageData<Type> = JSON.parse(message.data);
            
            if (messageData.key === key) {
                setState(messageData.data);
            }
        } catch {
            
        }
    }, [setState, key]);

    useEffect(() => {
        window.addEventListener('message', handleMessage);
        return () => {
            window.removeEventListener('message', handleMessage);
        }
    }, [handleMessage]);

    return state;
}

function useMapNUI<Type>(key: string, initialState: Map<string, Type>): Map<string, Type>
{
    const [state, setState] = useState(initialState);

    type MessageData = {
        key: string,
        data: {
            type: 'SET' | 'REMOVE'
            key: string,
            data?: Type
        }
    }

    const callback = useCallback((message) => {
        try {
            const messageData: MessageData = JSON.parse(message);

            if (messageData.key === key) {
                const newState = new Map(state);

                switch(messageData.data.type) {
                    case 'SET':
                        if (messageData.data.data) {
                            newState.set(messageData.data.key, messageData.data.data);
                        }
                    break;
                    case 'REMOVE': 
                        newState.delete(messageData.data.key);
                    break;
                }

                setState(newState);
            }
        } catch (e) {

        }
    }, [state, setState, key]);

    useEffect(() => {
        const iWindow = window as any;
        iWindow.handleMessage = callback;

        window.addEventListener('message', callback);
        return () => {
            window.removeEventListener('message', callback);
        }
    }, [callback]);

    return state;
}

export {
    useNUI, useMapNUI
}
